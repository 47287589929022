import React from "react";
import ReactDOM from "react-dom/client";
import { GlobalStyles } from "twin.macro";
import { ThemeProvider } from "@material-tailwind/react";
import { AuthProvider } from "./components/AuthContext";
import App from "./App";
import "./index.css";

// replace console.* for disable log on production
if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <GlobalStyles />
    <ThemeProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>
);
