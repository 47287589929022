import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { HomePage } from "../pages/HomePage";
import { useAuth } from "./AuthContext";
import Login from "./Login";
import { jwtDecode } from "jwt-decode";

export const AppRoutes = () => {
  const { token } = useAuth();

  const isAuthenticated = () => {
    if (!token) return false;
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decoded.exp > currentTime; // Check token expiration
    } catch (error) {
      return false;
    }
  };


  return (
    <Routes>
      <Route path="/" element={isAuthenticated() ? <HomePage /> : <Login />} />
      <Route path="/login" element={<Login />} />
    </Routes>
  );
};
