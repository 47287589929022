import React, { useState, useRef, useEffect } from "react";
import * as io from "socket.io-client";
import { Button, Typography } from "@mui/material";
import { DataGrid, GridToolbarExport, GridToolbarContainer, GridToolbarDensitySelector } from "@mui/x-data-grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useAuth } from "./AuthContext";
import { jwtDecode } from "jwt-decode";

const sampleRate = 16000;
const getMediaStream = () =>
  navigator.mediaDevices.getUserMedia({
    audio: {
      deviceId: "default",
      sampleRate: sampleRate,
      sampleSize: 16,
      channelCount: 1,
    },
    video: false,
  });

export const SpeechToText = () => {
  const [connection, setConnection] = useState();
  const [sendAudio, setSendAudio] = useState(false);
  const [transaction, setTransaction] = useState(false);
  const [currentTranscription, setCurrentTranscription] = useState("...");
  const [transcriptionHistory, setTranscriptionHistory] = useState([]);
  const [redactionHistory, setRedactionHistory] = useState([]);
  const [summary, setSummary] = useState("...");
  const [recommendationsGeneral, setRecommendationsGeneral] = useState("...");
  const [nextStepsCustomer, setNextStepsCustomer] = useState("...");
  const [nextStepsSales, setNextStepsSales] = useState("...");
  const [isRecording, setIsRecording] = useState(false);
  const [recorder, setRecorder] = useState();
  const [showRecommendations, setShowRecommendations] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const processorRef = useRef();
  const audioContextRef = useRef();
  const audioInputRef = useRef();
  const { token } = useAuth();

  const speechTranscribe = (data) => {
    setTranscriptionHistory((old) => [data, ...old]);
  };

  const speechTranscribeLive = (data) => {
    setCurrentTranscription(data.text);
  };

  const speechRedacted = (data) => {
    setRedactionHistory((old) => [data, ...old]);
  };

  const aiNextStepsSales = (data) => {
    setNextStepsSales(data.text);
  };

  const aiNextStepsCustomer = (data) => {
    setNextStepsCustomer(data.text);
  };

  const aiSummary = (data) => {
    setSummary(data.text);
  };

  const aiRecommendationsGeneral = (data) => {
    setRecommendationsGeneral(data.text);
  };

  const showSessionID = (data) => {
    setSessionId(data.text);
  }

  useEffect(() => {}, [
    transcriptionHistory,
    currentTranscription,
    recommendationsGeneral,
    summary,
    nextStepsCustomer,
    nextStepsSales,
  ]);

  const connect = () => {
    setTransaction(true);
    connection?.disconnect();
    setSendAudio(true);
    let socket = null;
    let url = "wss://verizon-" + jwtDecode(token).sttProvider + "-backend-t5iivsutoa-uc.a.run.app";
    if (process.env.REACT_APP_WS_BACKEND !== undefined) {
      console.log("localhost backend");
      url = process.env.REACT_APP_WS_BACKEND;
    } else {
      console.log("cloud run backend");
    }
    console.log(url);
    socket = io.connect(url);
    socket.on("connect", () => {
      console.log("connected", socket.id);
      setConnection(socket);
      socket.emit("setAgent", token);
    });

    socket.emit("startGoogleCloudStream");

    socket.on("receive_message", (data) => {
      console.log("received message", data);
    });

    socket.on("receive_live_audio_text", (data) => {
      speechTranscribeLive(data);
      console.log("received live audio text", data);
    });

    socket.on("receive_audio_text", (data) => {
      speechTranscribe(data);
      console.log("received audio text", data);
    });

    socket.on("receive_redacted_text", (data) => {
      speechRedacted(data);
      console.log("received redacted text", data);
    });

    socket.on("receive_summary_text", (data) => {
      aiSummary(data);
      console.log("received summary text", data);
    });

    socket.on("receive_recommendations_text", (data) => {
      aiRecommendationsGeneral(data);
      console.log("received recommendations text", data);
    });

    socket.on("receive_next_steps_sales_text", (data) => {
      aiNextStepsSales(data);
      console.log("received next steps sales text", data);
    });

    socket.on("receive_next_steps_customer_text", (data) => {
      aiNextStepsCustomer(data);
      console.log("received next steps customer text", data);
    });
    
    socket.on("receive_session_id", (data) => {
      showSessionID(data);
      console.log("received session id text", data);
    });

    socket.on("disconnect", () => {
      console.log("disconnected", socket.id);
    });

    socket.on("transaction_complete", () => {
      socket.disconnect();
      console.log("disconnected", socket.id);
    });
  };

  const stop = () => {
    if (!connection) return;
    connection?.emit("endGoogleCloudStream");
    //connection?.disconnect();
    processorRef.current?.disconnect();
    audioInputRef.current?.disconnect();
    audioContextRef.current?.close();
    setSendAudio(false);
    setIsRecording(false);
    setRecorder(undefined);
    setCurrentTranscription("...");
    setShowRecommendations(true);
  };

  const { logout } = useAuth();
  const handleLogout = () => {
    if (connection) {
      connection?.emit("endRecommendations");
      connection.disconnect();
    }
    logout();
    window.location.reload();
  };

  const clear = () => {
    if (connection) {
      connection?.emit("endRecommendations");
      connection.disconnect();
    }
    window.location.reload();
  };

  const columns = [
    {
      field: "timeStamp",
      headerName: "Timestamp",
      width: 120,
    },
    {
      field: "sessionId",
      headerName: "Session ID",
      width: 100,
    },
    {
      field: "interactionId",
      headerName: "Interaction ID",
      width: 100,
    },
    {
      field: "agentId",
      headerName: "Agent ID",
      width: 100,
    },
    {
      field: "speakerTag",
      headerName: "Speaker",
      width: 90,
    },
    {
      field: "text",
      headerName: "Text",
      width: 1000,
    },
  ];

  const recommendations = () => {
    setShowRecommendations(!showRecommendations);
  };

  const CustomToolbar = () => {
    return (
        <GridToolbarContainer style={{ display: "flex" }}>
          <GridToolbarExport
            color="primary"
            variant="outlined"
            sx={{
              mr: 2,
              pt: 0.8,
              pb: 0.8,
              "&:hover": {
                color: "#fff",
                backgroundColor: "primary.main",
              },
            }}
          />
          <GridToolbarDensitySelector sx={{ mr: 2, pt: 0.8, pb: 0.8 }} />
        </GridToolbarContainer>
    );
  }

  useEffect(() => {
    (async () => {
      if (connection) {
        if (sendAudio) {
          if (isRecording) {
            return;
          }

          const stream = await getMediaStream();

          audioContextRef.current = new window.AudioContext();

          await audioContextRef.current.audioWorklet.addModule(
            "/src/recorderWorkletProcessor.js"
          );

          audioContextRef.current.resume();

          audioInputRef.current =
            audioContextRef.current.createMediaStreamSource(stream);

          processorRef.current = new AudioWorkletNode(
            audioContextRef.current,
            "recorder.worklet"
          );

          processorRef.current.connect(audioContextRef.current.destination);
          audioContextRef.current.resume();

          audioInputRef.current.connect(processorRef.current);

          processorRef.current.port.onmessage = (event) => {
            const audioData = event.data;
            connection.emit("send_audio_data", { audio: audioData });
          };
          setIsRecording(true);
        }
      }
    })();
    return () => {
      if (isRecording) {
        processorRef.current?.disconnect();
        audioInputRef.current?.disconnect();
        if (audioContextRef.current?.state !== "closed") {
          audioContextRef.current?.close();
        }
      }
    };
  }, [connection, isRecording, recorder, sendAudio]);

  return (
    <React.Fragment>
      <div className="flex bg-black pt-2">
        <div className="pl-2"></div>
        <div>
          <Button variant="contained" onClick={connect} disabled={transaction}>
            Start
          </Button>
        </div>
        <div className="pl-3">
          <Button variant="contained" onClick={stop} disabled={!isRecording}>
            Stop
          </Button>
        </div>
        <div className="pl-10">
          <Button
            variant="contained"
            onClick={clear}
            disabled={!(isRecording || transaction)}
          >
            Clear
          </Button>
        </div>
        <div className="pl-3">
          <Button
            variant="contained"
            onClick={recommendations}
            disabled={!isRecording}
          >
            {showRecommendations
              ? "Hide Live Recommendations"
              : "Show Live Recommendations"}
          </Button>
        </div>
        <div className="pl-3">
          <Button variant="contained" onClick={handleLogout}>
            Logout
          </Button>
        </div>
        <div className="pr-2"></div>
      </div>
      <div className="flex-col bg-black">
        <div>
          <Typography className="text-white pl-2" variant="h6">
            Recommendations
          </Typography>
        </div>
        <div className="m-2 p-2 bg-white rounded-md">
          <pre className="text-black pl-2 text-xs whitespace-pre-wrap">
            {showRecommendations ? recommendationsGeneral : "..."}
          </pre>
        </div>
        <div>
          <Typography className="text-white pl-2" variant="h6">
            Summary
          </Typography>
        </div>
        <div className="m-2 p-2 bg-white rounded-md">
          <pre className="text-black pl-2 text-xs whitespace-pre-wrap">
            {summary}
          </pre>
        </div>
        <div className="flex">
          <Typography className="text-white pl-2" variant="h6">
            Next Steps -
          </Typography>
          <Typography className="text-red-700 pl-2" variant="h6">
            Sales Associate:
          </Typography>
        </div>
        <div className="m-2 p-2 bg-white rounded-md">
          <pre className="text-black pl-2 text-xs whitespace-pre-wrap">
            {nextStepsSales}
          </pre>
        </div>
        <div className="flex">
          <Typography className="text-white pl-2" variant="h6">
            Next Steps -
          </Typography>
          <Typography className="text-red-700 pl-2" variant="h6">
            Customer:
          </Typography>
        </div>
        <div className="m-2 p-2 bg-white rounded-md">
          <pre className="text-black pl-2 text-xs whitespace-pre-wrap">
            {nextStepsCustomer}
          </pre>
        </div>
        <div className="pt-2 bg-black">
          <Typography className="text-white pl-2" variant="body1">
            Current Input
          </Typography>
        </div>
        <div className="m-2 p-2 bg-white rounded-md">
          <Typography className="text-gray-400 pl-2" variant="body2">
            {currentTranscription}
          </Typography>
        </div>
        <div className="bg-black p-2">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <div className="text-red-700">
                <Typography>Live Transcript:</Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <DataGrid
                rows={transcriptionHistory}
                columns={columns}
                loading={false}
                disableColumnSelector={true}
                disableMultipleSelection={true}
                hideFooterPagination
                slots={{
                  toolbar: CustomToolbar,
                }}
              />
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="bg-black p-2">
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              <div className="text-red-700">
                <Typography>Redacted Diarization:</Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails>
              <DataGrid
                rows={redactionHistory}
                columns={columns}
                loading={false}
                disableColumnSelector={true}
                disableMultipleSelection={true}
                hideFooterPagination
                slots={{
                  toolbar: CustomToolbar,
                }}
              />
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
    </React.Fragment>
  );
};
