import React, { useState } from "react";
import axios from "axios";
import { useAuth } from "./AuthContext";
import { Button, TextField } from "@mui/material";
import { useForm } from "react-hook-form";

const Login = () => {
  let backend = "https://verizon-deepgram-backend-t5iivsutoa-uc.a.run.app";
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [sttProvider, setSttProvider] = useState("");

  if (process.env.REACT_APP_HTTP_BACKEND !== undefined) {
    backend = process.env.REACT_APP_HTTP_BACKEND;
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      sttProvider: "deepgram"
    },
    mode: "onChange"
  }, [username, password]);
  const { login } = useAuth();

  const onSubmit = async ({ username, password, sttProvider }) => {
    console.log(username);
    console.log(password);
    console.log(sttProvider);
    try {
      const response = await axios.post(backend + "/api/auth/login", {
        username: username,
        password: password,
        sttProvider: sttProvider,
      });
      login(response.data.accessToken);
      console.log(response.data.accessToken);
    } catch (error) {
      console.error(error); // Handle login errors
    } finally {
    }
  };

  return (
    <div className="flex bg-black pt-2">
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* Username and password fields */}
        <div className="flex-row">
          <div className="bg-white ml-2">
            <TextField
              id="username"
              label="username"
              size="small"
              {...register("username")}
            ></TextField>
          </div>
          <div className="p-2"></div>
          <div className="bg-white ml-2">
            <TextField
              id="password"
              label="password"
              type="password"
              size="small"
              {...register("password")}
            ></TextField>
          </div>
          <div className="p-2"></div>
        </div>
        <div className="p-2 text-white">
          <label>
            <input
              className="mr-2"
              type="radio"
              value="deepgram"
              {...register("sttProvider")}
            />
            Deepgram STT
          </label>
        </div>
        <div className="p-2 text-white" >
          <label>
            <input
              className="mr-2"
              type="radio"
              value="google"
              {...register("sttProvider")}
            />
            Google STT
          </label>
        </div>
        <div className="bg-black mt-2 p-2">
          <Button variant="contained" type="submit" onClick={handleSubmit}>
            Login
          </Button>
        </div>
      </form>
    </div>
  );
};

export default Login;
