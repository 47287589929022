import { AppRoutes } from "./components/AppRoutes";
import { BrowserRouter } from "react-router-dom";
import { Typography } from "@mui/material";
import { useAuth } from "./components/AuthContext";
import { jwtDecode } from "jwt-decode";
// eslint-disable-next-line react/prop-types
const App = ({ msalInstance }) => {
  const { token } = useAuth();
  return (
    <BrowserRouter>
      <div className="bg-black min-h-screen">
        <div className="bg-black ml-0 pt-2 sticky flex justify-between pl-5 pr-5">
          <Typography className="text-white text-3xl mr-4 cursor-pointer py-1.5 font-medium">
            Sidekick
          </Typography>
          <div>
            <Typography className="text-gray-400">
              version: {process.env.REACT_APP_VERSION}
            </Typography>
            {token ? (
            <Typography className="text-gray-400">
              STT Provider: {jwtDecode(token).sttProvider}
            </Typography>
            ) : null}
            {token ? (
              <Typography className="text-gray-400">
                {jwtDecode(token).sub} ({jwtDecode(token).employeeid})
              </Typography>
            ) : null}
          </div>
        </div>

        <hr className="bg-gray-700 h-px pt-2 border-0"></hr>
        <div className="flex">
          <div className="w-full bg-black">
            <AppRoutes />
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;
