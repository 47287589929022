import React, { createContext, useState, useContext, useEffect } from "react";
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const login = (newToken) => {
    setToken(newToken);
    localStorage.setItem("authToken", newToken);
  };
  const logout = () => {
    setToken(null);
    console.log("logout called");
    localStorage.removeItem("authToken");
  };

  const validateToken = (token) => {
    const decoded = jwtDecode(token);
    const expiry = decoded.exp * 1000;
    return Date.now() < expiry;
  };

  useEffect(() => {
    const storedToken = localStorage.getItem("authToken");
    if (storedToken && validateToken(storedToken)) {
      setIsAuthenticated(true);
      setToken(storedToken);
    }
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, token, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
