import React, { useState } from "react";
import BasicSnackbar from "./BasicSnackbar";
import Box from "@mui/material/Box";
import { SpeechToText } from "./SpeechToText";

const MainPageContent = () => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });


  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ open: false });
  };
  

  return (
    <>
      <Box component="main" sx={{ flexGrow: 1, mx: "auto" }}>
        <BasicSnackbar
          open={snackbar.open}
          severity={snackbar.severity}
          message={snackbar.message}
          onClose={handleClose}
          vertical="top"
          horizontal="right"
        />
        <SpeechToText />
      </Box>
    </>
  );
};

export default MainPageContent;
